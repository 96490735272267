import './Icon.scss';

function Icon() {
  return (
    <div className='icon'>
      <div className='p'>P</div>
      <div className='j'>J</div>
      <div className='planet' />
    </div>
  )
}

export default Icon;
