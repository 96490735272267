import './DataField.scss';

interface DataField {
  title: string;
  value: number | string;
}
function DataField({ title, value }: DataField): JSX.Element {
  return (
    <div className="meta-data-field">
      <div className="meta-data-field-title">{title}:</div>
      <div className="meta-data-field-value">{value}</div>
    </div>
  );
}

export default DataField;
