import { ChangeEventHandler, useEffect, useRef } from 'react';
import AmigaWindow from '../../atoms/AmigaWindow/AmigaWindow';
import FileUploader from '../FileUploader/FileUploader';
import './ImageViewer.scss';

interface ImageViewerProps {
  imageLoaded: boolean;
  renderIff: (canvas: HTMLCanvasElement) => void;
  handleUpload: ChangeEventHandler<HTMLInputElement>;
}
function ImageViewer({
  imageLoaded,
  renderIff,
  handleUpload
}: ImageViewerProps): JSX.Element {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  
  useEffect(() => {
    if (imageLoaded && canvasRef.current) {
      renderIff(canvasRef.current);
    }
  }, [imageLoaded, canvasRef]);

  return (
    <AmigaWindow title='Image' width={656} height={549}>
      <div className='image-viewer-container'>
        {imageLoaded && (
          <canvas
            ref={canvasRef}
            style={{
              width: 640,
              height: 512,
              imageRendering: 'pixelated'
            }}
          />
        )}
        {!imageLoaded && (
          <div className='image-viewer-uploader'>
            <FileUploader handleFileUpload={handleUpload} />
          </div>
        )}
      </div> 
    </AmigaWindow>
  );
}

export default ImageViewer;
