import { useMemo } from "react";
import ImageMeta from "../../molecules/ImageMeta/ImageMeta";
import ImageViewer from "../../molecules/ImageViewer/ImageViewer";
import Palette from "../../molecules/Palette/Palette";
import Controls from "../../molecules/Controls/Controls";
import useIff, { Status } from "../../../hooks/useIff";
import "./Iff2Png.scss";

function Iff2Png(): JSX.Element {
  // Iff processing
  const {
    handleUpload,
    status,
    renderIff,
    palette,
    metaData,
    clear,
    download
  } = useIff();

  // Show image
  const showImage = useMemo<boolean>(() => {
    if (status === Status.PARSE_SUCCESS || status === Status.RENDER_SUCCESS) {
      return true;
    }
    return false;
  }, [status])

  return (
    <div className="iff2png">
      <ImageViewer
        imageLoaded={showImage}
        handleUpload={handleUpload}
        renderIff={renderIff}
      />
      <div className="controls-container">
        <Palette
          imageLoaded={status === Status.RENDER_SUCCESS}
          colours={palette}
        />
        <Controls
          status={status}
          handleClear={clear}
          handleDownload={download}
        />
      </div>
      <ImageMeta
        metaData={metaData}
        imageLoaded={status === Status.RENDER_SUCCESS}
      />
    </div>
  )
}

export default Iff2Png;
