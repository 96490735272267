function Download() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 0 24 24"
    >
      <g>
        <g
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M3 12.3v7a2 2 0 002 2h14a2 2 0 002-2v-7"></path>
          <path d="M7.9 12.3L12 16.3 16.1 12.3" data-name="Right"></path>
          <path d="M12 2.7L12 14.2"></path>
        </g>
      </g>
    </svg>
  );
}

export default Download;
