import { ChangeEventHandler, MouseEventHandler, useRef } from 'react';
import AmigaIcon from '../../atoms/AmigaIcon/AmigaIcon';
import Download from '../../atoms/icons/Download/Download';
import './FileUploader.scss';

interface FileUploaderProps {
  handleFileUpload: ChangeEventHandler<HTMLInputElement>;
}
function FileUploader({ handleFileUpload }: FileUploaderProps) {
  const input = useRef<HTMLInputElement>(null);

  const onClick: MouseEventHandler<HTMLDivElement> = () => {
    input.current?.click();
  }

  return (
    <div className='file-uploader'>
      <div className='file-uploader-icon-container'>
        <AmigaIcon onClick={onClick}>
          <div className='file-uploader-content'>
            <Download />
          </div>
          <input
            ref={input}
            type="file"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
        </AmigaIcon>
      </div>
      <div className='file-uploader-text'>Choose a file</div>
    </div>
  );
}

export default FileUploader;
