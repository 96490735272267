import { MouseEventHandler, useMemo, useRef, useState } from 'react';
import './AmigaIcon.scss'

interface AmigaIconProps {
  onClick: MouseEventHandler<HTMLDivElement>;
  children: JSX.Element | JSX.Element[];
  disabled?: boolean;
}
function AmigaIcon({ onClick, children, disabled = false }: AmigaIconProps): JSX.Element {
  const icon = useRef<HTMLDivElement>(null);

  const [clicked, setClicked] = useState<boolean>(false);
  const iconClass = useMemo<string>(() => {
    if (clicked) {
      return 'amiga-icon clicked';
    }
    return 'amiga-icon';
  }, [clicked]);

  const _handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (disabled) {
      return; 
    }
    onClick(e);
  };

  const _handleMousDown = () => {
    if (disabled) {
      return;
    }
    setClicked(true);
  };

  const _handleMouseUp = () => {
    if (disabled) {
      return;
    }
    setClicked(false);
  };

  return (
    <div
      ref={icon}
      className={iconClass}
      onClick={_handleClick}
      onMouseDown={_handleMousDown}
      onMouseUp={_handleMouseUp}
    >
      {children}
    </div>
  );
}

export default AmigaIcon;
