import Iff2Png from "./components/organisms/Iff2Png/Iff2Png";
import Icon from "./components/atoms/Icon/Icon";
import './App.scss';

function App() {
  return (
    <>
      <div className="header">
        IFF 2 PNG - A tool for viewing IFF images and downloading as a PNG
      </div>
      <Iff2Png />
      <div className="footer">
        <Icon />
        <div>Planet January 2023</div>
      </div>
    </>
  )
}

export default App;
