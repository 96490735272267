import './AmigaWindow.scss';

interface AmigaWindowProps {
  title: string;
  width: number;
  height: number;
  children?: JSX.Element | JSX.Element[];
}
function AmigaWindow({
  title,
  width,
  height,
  children
}: AmigaWindowProps): JSX.Element {
  return (
    <div
      className='amiga-window'
      style={{
        width: `${width}px`,
        height: `${height}px`
      }}
    >
      <div className='amiga-window-title'>{title}</div>
      <div className='amiga-inner-window'>
        {children}
      </div>
    </div>
  )
}

export default AmigaWindow;
