import { useMemo } from 'react';
import AmigaWindow from '../../atoms/AmigaWindow/AmigaWindow';
import { ColourMap } from '../../../util/iff';
import './Palette.scss';

interface PaletteProps {
  imageLoaded: boolean;
  colours: ColourMap;
}
function Palette({
  imageLoaded,
  colours,
}: PaletteProps): JSX.Element {
  const minWidth = useMemo<string>(() => {
    if (colours.length > 16) {
      return '24px';
    }
    if (colours.length > 4) {
      return '48px'
    }
    return '96px';
  }, [colours])

  const minHeight = useMemo<string>(() => {
    if (colours.length > 32) {
      return '24px';
    }
    if (colours.length > 8) {
      return '48px';
    }
    if (colours.length > 2) {
      return '96px';
    }
    return '192px'
  }, [colours])

  return (
    <AmigaWindow title="Palette" width={112} height={420}>
      <div className='palette-colours'>
        {imageLoaded && colours.map((colour, i) => (
          <div
            key={`colour${i}`}
            className='palette-colour'
            style={{
              backgroundColor: colour,
              minWidth,
              minHeight
            }}
          />
        ))}
        {!imageLoaded && (
          <div className='palette-no-image'>NO IMAGE LOADED</div>
        )}
      </div>
    </AmigaWindow>
  )
}

export default Palette
