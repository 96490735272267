import { ChangeEvent, useEffect, useState } from "react";
import { parseIFF, ColourMap, RGB, IFFMetaData } from '../../util/iff'
import useFileUpload from '../useFileUpload'

export enum Status {
  READY,
  PROCESSING,
  PARSE_SUCCESS,
  PARSE_FAIL,
  RENDERING,
  RENDER_SUCCESS,
  RENDER_FAIL
}
interface UseIff {
  handleUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  status: Status;
  errorMessage: string;
  pixels: ColourMap;
  backgroundColour: RGB;
  metaData: IFFMetaData;
  palette: ColourMap;
  renderIff: (canvas: HTMLCanvasElement) => void;
  clear: () => void;
  download: () => void;
}

function useIff(): UseIff {
  const [status, setStatus] = useState<Status>(Status.READY);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [pixels, setPixels] = useState<ColourMap>([]);
  const [backgroundColour, setBackGroundColour] = useState<RGB>('rgb(0, 0, 0)');
  const [metaData, setMetaData] = useState<IFFMetaData>({
    width: 0,
    height: 0,
    left: 0,
    top: 0,
    bitplanes:  0,
    masking: 0,
    compress: 0,
    padding: 0,
    transparency: 0,
    xAspectRatio: 0,
    yAspectRatio: 0,
    pageWidth: 0,
    pageHeight: 0
  });
  const [palette, setPalette] = useState<ColourMap>([]);
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();

  const {
    uploadComplete,
    fileData,
    fileName,
    handleFileUpload 
  } = useFileUpload();

  function handleUpload(e: ChangeEvent<HTMLInputElement>) {
    setStatus(Status.PROCESSING);
    handleFileUpload(e);
  }

  useEffect(() => {
    if (uploadComplete) {
      try {
        const bytes = new Uint8Array(fileData)
        const { meta, backgroundColour, pixels, palette } = parseIFF(bytes)
        setStatus(Status.PARSE_SUCCESS);
        setBackGroundColour(backgroundColour);
        setPixels(pixels);
        setMetaData(meta);
        setPalette(palette);
      } catch {
        setStatus(Status.PARSE_FAIL);
        setErrorMessage('Unable to parse file');
      }
    }
  }, [uploadComplete, fileData])

  function renderIff(canvas: HTMLCanvasElement) {
    canvas.width = metaData.width;
    canvas.height = metaData.height;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    let x = 0;
    let y = 0;
    pixels.forEach((pixel) => {
      if (x === 320) {
        x = 0;
        ++y;
      }
      ctx.fillStyle = pixel
      ctx.fillRect(x, y, 1, 1);
      ++x;
    });
    setCanvas(canvas);
    setStatus(Status.RENDER_SUCCESS)
  }

  function clear() {
    setStatus(Status.READY);
  }

  function download() {
    if (canvas && status === Status.RENDER_SUCCESS) {
      const link = document.createElement("a");
      link.download = fileName
      link.href = canvas.toDataURL('image/png');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link)
    }
    return;
  }

  return {
    handleUpload,
    status,
    errorMessage,
    pixels,
    backgroundColour,
    metaData,
    palette,
    renderIff,
    clear,
    download
  }
}

export default useIff;
