import { useEffect, useMemo, useState } from "react";
import AmigaWindow from "../../atoms/AmigaWindow/AmigaWindow";
import AmigaIcon from "../../atoms/AmigaIcon/AmigaIcon";
import { Status } from "../../../hooks/useIff";
import "./Controls.scss";

interface ControlsProps {
  status: Status;
  handleClear: () => void;
  handleDownload: () => void;
}
function Controls({
  status,
  handleClear,
  handleDownload
}: ControlsProps): JSX.Element {
  const [clearDisabled, setClearDisabled] = useState<boolean>(true);
  const [downloadDisabled, setDownloadDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (status === Status.RENDER_SUCCESS) {
      setClearDisabled(false);
      setDownloadDisabled(false);
      return;
    }
    setClearDisabled(true);
    setDownloadDisabled(true);
  }, [status]);

  const clearIconClass = useMemo<string>(() => {
    if (clearDisabled) {
      return 'control-content disabled';
    }
    return 'control-content';
  }, [clearDisabled, downloadDisabled]);

  const downloadIconClass = useMemo<string>(() => {
    if (downloadDisabled) {
      return 'control-content disabled';
    }
    return 'control-content';
  }, [clearDisabled, downloadDisabled]);

  function _handleClear(): void {
    if (clearDisabled) {
      return;
    }
    handleClear();
  }

  function _handleDownload(): void {
    if (downloadDisabled) {
      return;
    }
    handleDownload();
  }

  return (
    <AmigaWindow title="Controls" width={112} height={129}>
      <div className="controls-container">
        <AmigaIcon onClick={_handleClear} disabled={clearDisabled}>
          <div className={clearIconClass}>Clear</div>
        </AmigaIcon>
        <AmigaIcon onClick={_handleDownload} disabled={downloadDisabled}>
          <div className={downloadIconClass}>Download</div>
        </AmigaIcon>
      </div>
    </AmigaWindow>
  )
}

export default Controls;
