import { Chunk, IFFMetaData, ColourMap, IFF } from './types';

export function bytesToArray(
  bytes: Uint8Array,
  numberOfBytes: number = 4,
  offset: number = 0
): number[] {
  return Array(numberOfBytes).fill(1).map((_, i) => {
    return bytes[i + offset];
  })
}

export function binaryArrayToString(bin: number[]): string {
  return String.fromCharCode(...bin);
}

export function binaryArrayToNumber(bin: number[]): number {
  return parseInt(
    bin.map(byte => byte.toString(2).padStart(8, '0')).join(''),
    2
  );
}

export function validateIFF(file: Uint8Array): boolean {
  if (binaryArrayToString(bytesToArray(file)) !== 'FORM') {
    return false;
  }

  if (binaryArrayToString(bytesToArray(file, 4, 8)) !== 'ILBM') {
    return false;
  }

  return true;
}

export function fileToChunks(
  file: Uint8Array,
  position: number = 0,
  chunks: Chunk[] = []
): Chunk[] {
  let index = position;
  const id = binaryArrayToString(bytesToArray(file, 4, index));
  const length = binaryArrayToNumber(bytesToArray(file, 4, index + 4));

  const start = index + 8
  const end = index + length + 8

  const data = file.slice(start, end)

  const chunksSoFar = [...chunks, {
    id,
    length,
    data
  }]

  if (end >= file.length - 1) {
    return chunksSoFar;
  }
  return fileToChunks(file, end, chunksSoFar);
}

export function getFileMetaData({ id, length, data }: Chunk): IFFMetaData {
  if (id !== 'BMHD') {
    throw new Error('Must be BMHD chunk for file meta data');
  }

  if (length !== 20) {
    throw new Error('Metadata data blocks must be 20 bytes in length');
  }
 
  return { 
    width: binaryArrayToNumber(bytesToArray(data, 2)),
    height: binaryArrayToNumber(bytesToArray(data, 2, 2)),
    left: binaryArrayToNumber(bytesToArray(data, 2, 4)),
    top: binaryArrayToNumber(bytesToArray(data, 2, 6)),
    bitplanes: binaryArrayToNumber(bytesToArray(data, 1, 8)),
    masking: binaryArrayToNumber(bytesToArray(data, 1, 9)),
    compress: binaryArrayToNumber(bytesToArray(data, 1, 10)),
    padding: binaryArrayToNumber(bytesToArray(data, 1, 11)),
    transparency: binaryArrayToNumber(bytesToArray(data, 2, 12)),
    xAspectRatio: binaryArrayToNumber(bytesToArray(data, 1, 14)),
    yAspectRatio: binaryArrayToNumber(bytesToArray(data, 1, 15)),
    pageWidth: binaryArrayToNumber(bytesToArray(data, 2, 16)),
    pageHeight: binaryArrayToNumber(bytesToArray(data, 2, 18)),
  }
}

export function getColourMap(
  { id, length, data }: Chunk,
  bitplanes: number
): ColourMap {
  if (id !== 'CMAP') {
    throw new Error('Must be CMAP chunk for file meta data');
  }

  if (length !== (2 ** bitplanes) * 3) {
    throw new Error('Metadata data blocks must be 20 bytes in length');
  }
 
  let colourMap: ColourMap = []
  for (let i = 0; i < data.length; i += 3) {
    if (i > 95) {
      colourMap.push(`rgb(${data[i - 96] >> 1}, ${data[i + 1 - 96] >> 1}, ${data[i + 2 - 96] >> 1})`);
    } else {
      colourMap.push(`rgb(${data[i]}, ${data[i + 1]}, ${data[i + 2]})`);
    }
  }
 
  return colourMap;
} 

export function decompress(data: Uint8Array): Uint8Array {
  const numbers = bytesToArray(data, data.length)
  let dataIndex: number = 0;
  let unpacked: number[] = [];
  
  while (dataIndex < numbers.length) {
    let number = numbers[dataIndex]
    if (number > 128) {
      number = 256 - number;

      for (var j = 0; j <= number; ++j) {
        unpacked.push(numbers[dataIndex + 1]);
      }

      ++dataIndex;
    }
    else if (number < 128) {
      for (var j = 0; j <= number; ++j) {
        unpacked.push(numbers[dataIndex + j + 1]);
      }

      dataIndex += j;
    }
    ++dataIndex
  }
  
  return new Uint8Array([...unpacked])
}

export function mapPixelsToColourIndices(
  data: Uint8Array,
  width:number,
  bitplanes: number
): number[] {
  const scanlines = (bytesToArray(data, data.length)
    .map(num => num.toString(2).padStart(8, '0'))
    .join('')
    .match(new RegExp(`.{${width * bitplanes}}`, 'g')) as string[])
    .map(scanline => scanline.match(new RegExp(`.{${width}}`, 'g')));
  const colours: number[] = [];
  (scanlines as string[][]).forEach((scanLine) => {
    Array(width).fill(1).forEach((_, x) => {
      let plane: string[] = []
      Array(bitplanes).fill(1).forEach((_, bit) => {
        plane.push(scanLine[bit].charAt(x))
      })
      colours.push(parseInt(plane.reverse().join(''), 2));
      plane = [];
    })
  })
  return colours;
}

export function parseIFF(file: Uint8Array): IFF {
  const chunks = fileToChunks(file.slice(12));
  const bmhd = chunks.find(({ id }) => id === 'BMHD') as Chunk;
  const meta = getFileMetaData(bmhd);
  const cmap = chunks.find(({ id }) => id === 'CMAP') as Chunk;
  const colourMap = getColourMap(cmap, meta.bitplanes);
  const body = chunks.find(({ id }) => id === 'BODY') as Chunk;
  const pixelsToColourIndices = (() => {
    if (meta.compress) {
      return mapPixelsToColourIndices(
        decompress(body.data),
        meta.width,
        meta.bitplanes
      );
    }
    return mapPixelsToColourIndices(body.data, meta.width, meta.bitplanes);
  })();
  

  return {
    meta,
    backgroundColour: colourMap[meta.transparency],
    pixels: pixelsToColourIndices.map(index => colourMap[index]),
    palette: colourMap
  }
}
