import AmigaWindow from '../../atoms/AmigaWindow/AmigaWindow';
import DataField from '../../atoms/DataField/DataField';
import { IFFMetaData } from '../../../util/iff'
import './ImageMeta.scss';

interface MetaDataProcessing {
  key: keyof IFFMetaData;
  title: string;
  valueProcessor?: (value: number) => number | string;
}
const PROCESSING: MetaDataProcessing[] = [
  {
    key: 'width',
    title: 'Image Width'
  },
  {
    key: 'height',
    title: 'Image Height'
  },
  {
    key: 'pageWidth',
    title: 'Page Width'
  },
  {
    key: 'pageHeight',
    title: 'Page Height'
  },
  {
    key: 'top',
    title: 'Top Pixel Start'
  },
  {
    key: 'left',
    title: 'Left Pixel Start'
  },
  {
    key: 'bitplanes',
    title: 'Colours',
    valueProcessor: (value: number) => 2 ** value
  },
  {
    key: 'compress',
    title: 'Compressed',
    valueProcessor: (value: number) => value ? 'Yes' : 'No'
  }
]

interface ImageMetaProps {
  metaData: IFFMetaData;
  imageLoaded: boolean;
}
function ImageMeta({ imageLoaded, metaData }: ImageMetaProps): JSX.Element {
  return (
    <AmigaWindow
      title='Image Metadata'
      width={768}
      height={170}
    >
      <div className='meta-data-container'>
        {imageLoaded && PROCESSING.map(({ key, title, valueProcessor }, i) => {
          const value = (() => {
            if (valueProcessor) {
              return valueProcessor(metaData[key]);
            }
            return metaData[key];
          })();
          return (
            <DataField key={`dataField${i}`} title={title} value={value} />
          );
        })}
        {!imageLoaded && (
          <div className='meta-data-no-image'>NO IMAGE LOADED</div>
        )}
      </div>
    </AmigaWindow>
  );
}

export default ImageMeta;
