import { ChangeEvent, useState } from 'react';

interface UseFileUpload {
  uploadComplete: boolean;
  fileData: ArrayBuffer;
  fileName: string;
  handleFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
}
function useFileUpload(): UseFileUpload {
  const [fileData, setFileData] = useState<ArrayBuffer>(new ArrayBuffer(1));
  const [fileName, setFileName] = useState<string>('');
  const [uploadComplete, setUploadComplete] = useState(false);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const buffer = event.target?.result as ArrayBuffer;
        setFileData(buffer);
        setFileName(file.name);
        setUploadComplete(true);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return {
    uploadComplete,
    fileData,
    fileName,
    handleFileUpload
  };
}

export default useFileUpload;
